@import '../../../styles/globals.scss';

.RoundButton {
  padding: 3px !important;
  margin: 0;
  height: 40px;
  width: 40px;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  svg {
    fill: #fff;
  }
  span {
    height: 27px;
    display: flex;
    align-items: center;
  }
}
