$fontColor: #4a4a4a;
$background: #f7f8f8;
$backgroundHover: #bfbfbf;
$divider: #9b9b9b;

.transfer-unfulfilled-request-summary {
  .btn-link {
    padding: 5px;
  }
}

$padding-big: 40px;
$padding-regular: 24px;
$padding-small: 16px;
$padding-min: 4px;
$padding-bootstrap: 15px;

.estimation-disclaimer {
  margin: 3rem 0;
  font-size: 12px;
  font-style: italic;
}

.estimation-details {
  background-color: $background;
  color: $fontColor;
  position: relative;
  width: 100%;
  min-height: 200px;
  min-width: 440px;
  margin-top: $padding-regular;

  &__header {
    display: flex;
    flex-flow: row nowrap;
    text-align: left;
    border-bottom: 1px solid $divider;
    font-weight: 600;
    & > div {
      padding: 1rem 2rem;
    }
  }

  .header {
    &__benefits {
      flex: 0 0 15rem;
    }
    &__details {
      flex: 1 0 150px;
    }
    &__sum {
      flex: 0 0 10rem;
      margin-right: 2rem;
      padding-right: 0;
      text-align: right;
    }
  }

  .mdi-close {
    cursor: pointer;
    color: $fontColor;
    position: absolute;
    top: 0;
    right: 10px;
  }
  .strong {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .light {
    font-size: 12px;
    line-height: 16px;
  }

  &-wrapper {
    margin: 10px;
  }

  &-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    height: 5rem;

    &-text {
      flex: 0 0 15rem;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      color: $fontColor;
      font-size: 14px;
    }

    &-amount {
      white-space: nowrap;
      flex: 0 0 10rem;
      text-align: right;
      &--linethrough {
        text-decoration: line-through;
      }
    }

    &-message {
      flex: 1 0 130px;
      font-size: 12px;
      margin-right: 2rem;
    }
  }

  .requested-loads {
    cursor: pointer;
    &:hover {
      background-color: $backgroundHover;
    }
  }

  .total-line-item {
    .estimation-details-row {
      width: 100%;
      &-text {
        flex: 1 0 15rem;
      }
      &-message {
        flex: 0 0 1px;
      }
      &-amount {
        flex: 0 0 10rem;
      }
    }
    border-top: 1px solid $divider;
    padding: 0;
  }
}
