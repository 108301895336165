@import '../../../styles/globals.scss';

.TransactionsHistoryTable {
  padding-top: 40px;
  .table-responsive,
  .table {
    margin-bottom: 0;
  }

  .no-content,
  .loading {
    th,
    td {
      margin-top: 6px;
      font-size: 20px;
      padding-bottom: 30px;
      height: 150px;
      vertical-align: middle;
      border-bottom: 2px solid transparent;
    }
  }

  & th {
    padding: 5px;
    color: $dark-blue;
    font-size: 11pt;
  }

  td {
    color: $gray-text-color;
    font-size: 11pt;
  }

  .centered {
    text-align: center;
  }

  .subtitle {
    color: $gray-text;
    font-size: 10pt;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media screen and (max-width: 300px) {
      font-size: 9pt;
    }
  }

  .mobile-row {
    color: #444;
    text-align: left;
    font-size: 12pt;
    transition: all 0.4s;
    td {
      position: relative;
      .mobile-cell {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
      }
    }
    &:hover {
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }

  .standard-row {
    td {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
  .col-chevron {
    font-size: 20pt;
    height: 85px;
  }
}
