@import '../../../styles/globals.scss';

.OrderSummary {
  color: $dark-blue;

  .bold {
    font-weight: 500;
  }

  .align-to-right {
    text-align: right;
  }

  .word-break {
    word-break: break-word;
  }

  .padding-right {
    padding-right: 10px !important;
  }
  .padding-left {
    padding-left: 5px !important;
  }

  .mdi-information-outline {
    font-size: 14px;
  }

  .container {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .btn-link {
    font-size: 13px;
    line-height: 15px;
    padding: 0;
  }

  .col-sm-8 {
    .body:last-child {
      font-size: 14px;
      .col-xs-2 {
        padding: 0;
      }
      border-bottom: 1px solid $dark-blue;
    }
  }

  .col-sm-8 > .row {
    margin: 0;
    padding: 16px 0 16px 0;
    border-bottom: 1px solid $line-color;
  }

  .headers {
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    padding: 0 0 5px 0 !important;
    .row {
      margin: 0;
    }
    div {
      padding: 0;
    }
  }

  .body {
    line-height: 16px;
    .row {
      margin: 0;
    }
    & > .col-xs-10,
    & > .col-xs-12 {
      & > .row {
        padding: 8px 0 8px 0;
      }
      & > .row:first-child {
        padding: 0 0 8px 0;
      }
      & > .row:last-child {
        padding: 8px 0 0 0;
      }
      .mdi-information-outline {
        margin-left: 10px;
      }
    }
    .col-xs-10 {
      .btn-link {
        padding-left: 10px;
      }
    }
    div {
      padding: 0;
    }
    .description {
      font-size: 11px;
    }
  }
}
