$fontColor: #4a4a4a;
$background: #f7f8f8;

$padding-big: 30px;
$padding-regular: 24px;
$padding-small: 16px;
$padding-min: 4px;

.error-details {
  background-color: $background;
  color: $fontColor;
  position: relative;
  width: 100%;
  padding: $padding-small $padding-big - 15;
  margin-top: $padding-regular;
  &-row {
    margin: $padding-regular 0;
    &-no-padding {
      .error-details-row {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .col-xs-2 {
      padding: 0;
    }
  }
}
