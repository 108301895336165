@import '../../../styles/globals.scss';

.VoucherQuantity {
  background-color: white;
  margin-top: -10px;
  padding: 0;

  input.form-control {
    height: 39px !important;
  }

  .extend-horizontal-margins {
    margin-left: -30px;
    margin-right: -30px;
  }
  .no-horizontal-padding {
    padding-left: 0;
    padding-right: 0;
  }
  .voucher-qty-field {
    &.has-error {
      input {
        color: $brand-danger;
      }
    }
  }
  .value-changed-warning {
    color: $brand-danger;
    font-weight: $font-weight-medium;
    .mdi {
      font-size: 18px;
      line-height: 16px;
    }
  }
  .adviced-value {
    color: $blue-text;
  }
  .vertical-center {
    position: relative;
    transform: translateY(-25%);
  }
  .voucher-image {
    max-height: 100px;
  }
  .tooltip-info {
    float: right;
  }
  .content-paddings {
    padding-left: 5px;
  }
}
