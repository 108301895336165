@import '../../../styles/globals.scss';

.TooltipInfo {
  &.mdi {
    font-size: 14pt;
    color: #666;
    cursor: pointer;
  }
  &.tooltip-info {
    .tooltip.left .tooltip-arrow {
      border-left-color: #757575;
    }
    .tooltip-inner {
      max-width: 230px;
      padding: 3px 8px;
      color: #757575;
      text-align: center;
      background-color: #fff;
      border-radius: 3px;
      border: solid 2px #757575;
    }
  }
}

.icon {
  display: flex;
  justify-content: flex-end;
}
