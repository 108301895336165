@import '../../../styles/globals.scss';
.ResetPasswordRequested {
  margin-top: 10px;

  p {
    margin: 20px 0 20px 0;
    line-height: 1.6;
  }

  .button-margin {
    margin: 15px 0 30px 0;
    [class*='col-'] {
      padding: 0;
    }
  }
}
