@import '../../../styles/globals.scss';

.OrderDetails {
  padding-left: 10px;
  padding-bottom: 150px;
  width: 80%;
  border-bottom: 1px $border-gray-color solid;

  h2 {
    margin-bottom: 30px;
    display: inline-block;
  }

  h3 {
    margin: 0 0 5px 0;
  }

  .caption4 {
    margin-top: 15px;
  }

  .btn-download {
    width: 310px;
    .spinner-container {
      font-size: 28px !important;
      padding-right: 10px;
    }
  }

  .row {
    flex-direction: column;
  }
}
