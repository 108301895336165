@import '../../../../styles/globals.scss';

.DownloadInvoice {
  .transparent-background {
    &,
    input {
      background-color: transparent;
    }
  }
}
