@import '../../../styles/globals.scss';
.BenefitSummaryContent {
  &.benefit-summary-content {
    padding: 15px 30px 0 30px;
    hr {
      margin-bottom: 5px;
      margin-top: 5px;
      &.gray {
        border-top: 1px solid $gray-light;
      }
    }
    h3.small {
      margin: 0;
    }
  }
}
