@import '../../../styles/globals.scss';

.VoucherOrderPayment {
  @include form-content();

  h4 {
    margin-bottom: 0;
  }

  .radio-field label {
    color: $gray-light;
  }

  .px-10,
  .order-payment-method {
    padding-top: 0;
  }
}
