@import '../../../styles/globals.scss';

.ConfirmPasswordPopup {
  .btn-link {
    margin-left: -15px;
  }

  .alert {
    margin-top: 10px;
    margin-bottom: -10px;
  }

  .modal-content {
    padding: 10px;
  }

  .password-row {
    padding-top: 25px;
  }

  .modal-footer {
    border: none;
  }
}
