@import './custom-variables';

$gray: #4a4a4a !default;
$body-size: 14px;
$brand-info: #162056 !default;
$brand-danger: #e60a14 !default;
$brand-default: #fbfbfc !default;
$brand-primary: #162056 !default;
$header-size: 22px;
$header-small-size: 20px;
$headings-font-weight: 500 !default;

$panel_background-color: #e8f4fa;
$border-gray-color: #929292;
$border-blue-color: #0b85c8;
$blue-background: #0b85c8;
$translucid-gray: rgba(0, 0, 0, 0.35);
$blue-text: #0b85c8;
$mobileModeWidth: 600px;
$tabletModeWidth: 1030px;
$gray-text: #9e9e9e;
$dark-blue: #202356;
$gray-light: #9b9b9b;
$background-blue-color: #2196f3;
$gray-text-color: #606060;
$line-color: #c0c0c0;
$hr-color: #eaeaea;

@mixin mobile-tabs() {
  @media screen and (max-width: $tabletModeWidth) {
    .react-tabs {
      &__tab {
        padding: 4px;
        min-width: 50%;
      }
    }
  }
}

@mixin mobile-tabs-smaller-font($maxWidth, $fontSize: 12px) {
  @media screen and (max-width: $maxWidth) {
    .react-tabs {
      &__tab {
        font-size: $fontSize;
      }
    }
  }
}

@mixin panel-paddings() {
  .panel-body,
  .panel-heading {
    padding: 25px;
  }
  .panel-heading {
    padding-bottom: 0;
  }
  .panel-body {
    padding-top: 30px;
  }
}

@mixin form-content() {
  margin-left: 5%;
  margin-right: 5%;
  @media screen and (max-width: $mobileModeWidth) {
    margin-left: 0;
    margin-right: 0;
  }
}

@mixin remove-grey-click-effect() {
  background-image: none;
  box-shadow: none;
}

@mixin tile() {
  width: 300px;
  height: 300px;
  float: left;
  margin: 0 20px 20px 0;
  @media (max-width: 1224px) and (min-width: 992px), (max-width: 944px) {
    width: 100%;
  }
}

@mixin table() {
  border-bottom: 1px solid #ddd;
  thead {
    th,
    .btn-link {
      padding-left: 0 !important;
      font-size: 13px;
      font-weight: 300;
    }
    .btn-link {
      padding: 0;
    }
  }
  tbody {
    td {
      vertical-align: middle !important;
    }
  }
}

@mixin pagination {
  .beneficiaries-count {
    padding-right: 15px;
    display: block;
    float: left;
    line-height: 30px;
  }
  .beneficiaries-pagging {
    display: block;
    float: right;
  }
  .pagination {
    display: inline;
  }
}
