@import '../../../styles/globals.scss';
.LoadingPanel {
  .panel {
    overflow: hidden;
  }
  .panel::before {
    content: '';
    display: block;
    position: relative;
    height: 2px;
    width: 100%;
    top: 0;
    background-color: transparent;
  }
  .panel.isloading::before {
    background-color: $blue-text;
    animation: loading 2s linear infinite;
  }
  @keyframes loading {
    from {
      left: -200px;
      width: 30%;
    }
    50% {
      width: 30%;
    }
    70% {
      width: 70%;
    }
    80% {
      left: 50%;
    }
    95% {
      left: 120%;
    }
    to {
      left: 100%;
    }
  }
}
