@import '../../../styles/globals.scss';
.VoucherOrder {
  padding-bottom: 20px;
  &.order-registered,
  &.order-anonymous {
    @include form-content();
  }
  @include form-content();
  .btn-edit {
    min-width: 120px;
  }
  .panel-heading {
    padding-left: 25px;
  }
  @include panel-paddings();

  .current-page-1 {
    .page1 {
      display: block;
    }
  }

  .current-page-2 {
    .page2 {
      display: block;
    }
    .container-fluid {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .current-page-3 {
    .page3 {
      display: block;
    }
  }

  .current-page-2,
  .current-page-3 {
    .page1 {
      display: none;
    }
  }

  .current-page-1,
  .current-page-3 {
    .page2 {
      display: none;
    }
  }

  .current-page-1,
  .current-page-2 {
    .page3 {
      display: none;
    }
  }
  hr {
    display: inline-block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 0;
    padding: 0;
    color: $hr-color;
    width: 100%;
  }
  .form-control-static {
    padding-top: 0;
  }
  .panel-body {
    .checkbox-field,
    .radio-field {
      label {
        color: $gray-light;
      }
      .order-page-voucher-quantity {
        padding-left: 35px;
        padding-right: 53px;
        @media screen and (max-width: $mobileModeWidth) {
          padding-left: 0;
          padding-right: 18px;
        }
      }
      .order-page-benefit-summary {
        padding-left: 55px;
        padding-right: 70px;
        @media screen and (max-width: $mobileModeWidth) {
          padding-left: 20px;
          padding-right: 35px;
        }
      }
    }
  }

  .px-10 {
    padding-top: 0;
  }
}
