@import '../../../styles/globals.scss';

.OrderStatus {
  @include form-content();

  h2 {
    line-height: 1.5;
    margin: 0;
    padding: 10px 0 10px 0;
  }

  .panel {
    padding-left: 10px;
    padding-right: 10px;
    .panel-heading,
    .panel-title {
      font-size: inherit;
      font-weight: inherit;
      .mdi {
        font-size: 70px;
      }
    }
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 0;
  }

  .col-sm-2,
  .col-xs-3 {
    h1 {
      margin-top: 0;
    }
  }

  .no-wrap {
    margin: 0px;
    display: flex;
    h1 {
      display: inline-block;
    }
    .text {
      @media screen and (max-width: 1540px) {
        width: 90%;
      }
    }
    .icon {
      padding-left: 15px;
      position: absolute;
      margin: -5px 0 0 0;
      @media screen and (max-width: 1540px) {
        width: 10%;
      }
    }
  }

  .row {
    .payment-link {
      color: $brand-info;
      @media screen and (max-width: 400px) {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
