/* OneTrust Cookie Icon */
#ot-sdk-btn-floating.ot-floating-button {
  left: auto !important;
  top: auto !important;
  right: 20px !important;
  bottom: 15px !important;
  z-index: 1 !important;

  &::before {
    transform: translate(0.5em, -50%) scale(1) rotate(180deg) !important;
    left: auto !important;
    right: 60px !important;
  }
  &::after {
    left: auto !important;
    right: 64px !important;
    font-size: 12px !important;
  }
}
