@import '../../../styles/globals.scss';

.FixedPanel {
  overflow: hidden;
  width: 700px;
  margin: auto;
  @media screen and (max-width: 720px) {
    width: 100%;
  }
}
