.TermsAndConditions {
  .help-block {
    padding-left: 35px;
  }

  .validate-company-margin {
    margin-bottom: -30px;
  }

  .error-message {
    color: #d32f2f;
    font-size: 12px;
  }
}
