$web-font-path: 'https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700' !default;

$input-text-color: #606060;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$helpblock-size: 12px;
$placeholder-size: 16px;
$button-icon-size: 25px;
$table-header-icon-size: 12px;
