@import '../../../styles/globals.scss';

.DeliveryPicker {
  .btn-edit {
    position: relative;
    bottom: 0;
    right: 0;
  }
  .btn-pencil {
    padding: 2px;
    margin: 0;
    height: 45px;
    width: 45px;

    display: flex;
    justify-content: center;
    align-items: center;

    .mdi {
      padding-right: 0px;
      line-height: 10px;
    }
  }
}
