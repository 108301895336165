@import '../../../styles/globals.scss';

.NavigationHeader {
  &-header {
    @include form-content();
    .btn-back {
      margin-top: -5px;
      margin-left: -12px;
    }
    .title {
      display: inline-block;
      height: 30px;
      margin-bottom: 0px;
    }
    .subtitle {
      display: block;
    }

    &.with-back-button {
      @include form-content();
    }
  }

  &-body {
    @include form-content();
  }
}
