@import '../../../styles/globals.scss';

.TransactionsHistory {
  .pagination {
    display: flex;
    padding-left: 0px;
    justify-content: center;
    border-radius: 0;

    li,
    span,
    a {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .page-item.active .page-link {
      background-color: #162056;
      border-color: #162056;
    }

    > li:not(.active) {
      &:focus,
      a:focus {
        outline-color: transparent;
        background-color: transparent;
      }
    }
  }

  .with-separator {
    margin-top: 35px;
    border-top: 2px solid #ddd;
  }

  @media screen and (max-width: $mobileModeWidth) {
    .hidden-in-mobile {
      display: none;
    }
  }
}
