@import '../../../styles/globals.scss';

.OrderAmount {
  hr {
    display: inline-block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 0;
    padding: 0;
    color: $hr-color;
    margin-bottom: 20px;
    margin-top: 0px;
    width: 100%;
  }
  .calculator-button,
  .calculator-button:focus {
    @media screen and (max-width: 450px) {
      margin-left: 8px;
    }
  }
  .order-amount-order-calculator-container {
    padding-right: 53px;
    padding-left: 35px;
    @media screen and (max-width: $mobileModeWidth) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .order-amount-order-details-container {
    padding-right: 70px;
    padding-left: 55px;
    @media screen and (max-width: $mobileModeWidth) {
      padding-left: 20px;
      padding-right: 30px;
    }
  }
}
