// Based on https://bootswatch.com/3/paper/
// Paper 3.3.7
// Bootswatch
// -----------------------------------------------------

// Navbar =====================================================================

.navbar {
  border: none;
  @include box-shadow(0 1px 2px rgba(0, 0, 0, 0.3));

  &-brand {
    font-size: 24px;
  }

  &-inverse {
    .navbar-form {
      input[type='text'],
      input[type='password'] {
        color: #fff;
        @include box-shadow(inset 0 -1px 0 $navbar-inverse-link-color);
        @include placeholder($navbar-inverse-link-color);

        &:focus {
          @include box-shadow(inset 0 -2px 0 #fff);
        }
      }
    }
  }
}

// Buttons ====================================================================

@mixin btn($class, $bg) {
  .btn-#{$class} {
    background-size: 200% 200%;
    background-position: 50%;

    &:focus {
      background-color: $bg;
      border-color: $brand-primary;
    }

    &:hover,
    &:active:hover {
      background-color: darken($bg, 6%);
    }

    &:active {
      background-size: 1000% 1000%;
      border-color: $brand-primary;
    }
  }
}

@include btn(success, $btn-success-bg);
@include btn(warning, $btn-warning-bg);
@include btn(danger, $btn-danger-bg);

.btn {
  // Force button height to 36px
  line-height: 30px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: $font-size-base;

  border-radius: $btn-border-radius-base;

  &-lg {
    font-size: $font-size-large;
    padding-top: $padding-large-vertical;
    padding-bottom: $padding-large-vertical;
  }
  &-sm {
    font-size: $font-size-small;
    padding-top: $padding-small-vertical;
    padding-bottom: $padding-small-vertical;
  }
  &-xs {
    font-size: $font-size-small;
    padding-top: $padding-small-vertical;
    padding-bottom: $padding-small-vertical;
  }

  &[disabled],
  &[disabled]:focus,
  &[disabled]:hover {
    color: $btn-disabled-color;
    background-color: $btn-disabled-bg;
    border-color: $btn-disabled-border;
    opacity: 1;
  }

  @include transition(all 0.4s);

  &-link {
    @include box-shadow(none);
    color: $btn-link-color;
    background-color: $btn-link-bg;
    border-color: $btn-link-border;

    &:active,
    &:hover:active {
      color: $btn-link-color;
      background-color: $btn-disabled-color !important;
      text-decoration: none;
    }
    &:hover,
    &:focus {
      color: mix($btn-link-color, #000000, 20%);
      text-decoration: none;
    }
    &[disabled],
    &[disabled]:focus,
    &[disabled]:hover {
      background-color: $btn-link-bg;
      border-color: $btn-link-border;
    }
  }

  &-primary {
    &:hover {
      background-color: mix(#999999, $btn-primary-bg, 20%);
    }
    &:active,
    &:hover:active {
      background-color: mix(#bdbdbd, $btn-primary-bg, 30%);
    }
    &[disabled],
    &[disabled]:focus,
    &[disabled]:hover {
      color: $btn-disabled-bg;
      background-color: $btn-disabled-color;
    }
  }

  &-info {
    &:hover,
    &:focus {
      background-color: mix(#999999, $btn-info-bg, 20%);
    }
    &:active,
    &:hover:active {
      background-color: mix(#bdbdbd, $btn-info-bg, 30%);
    }
  }

  &-default {
    border: 1px solid $btn-default-border;
    &:hover {
      background-color: mix(#999999, $btn-default-bg, 20%);
    }
    &:active,
    &:hover:active {
      background-color: mix(#bdbdbd, $btn-default-bg, 30%);
    }
    &[disabled],
    &[disabled]:focus,
    &[disabled]:hover {
      color: $btn-disabled-bg;
      background-color: $btn-disabled-color;
    }
  }

  .mdi {
    font-size: $button-icon-size;
    line-height: 26px;
    padding-right: 10px;
    vertical-align: middle;
  }
}

.btn-group {
  .btn + .btn,
  .btn + .btn-group,
  .btn-group + .btn,
  .btn-group + .btn-group {
    margin-left: 0;
  }

  &-vertical {
    > .btn + .btn,
    > .btn + .btn-group,
    > .btn-group + .btn,
    > .btn-group + .btn-group {
      margin-top: 0;
    }
  }
}

// Typography =================================================================

body {
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.1px;
  font-weight: $font-weight-regular;
  .second {
    font-weight: $font-weight-medium;
  }
}

p {
  margin: 0 0 1em;
}

input,
button {
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.1px;
}

input {
  font-weight: 400;
  font-size: $subheader-size;
}

a {
  @include transition(all 0.2s);
}

// Tables =====================================================================

.table-hover {
  > tbody > tr,
  > tbody > tr > th,
  > tbody > tr > td {
    @include transition(all 0.2s);
  }
}

.table-striped > tbody > tr {
  &:nth-of-type(even) {
    background-color: $table-bg-accent;
  }
  &:nth-of-type(odd) {
    background-color: transparent;
  }
}

.table {
  border-bottom: 1px solid #ddd;
  font-size: $caption-size;
  thead {
    @extend .caption4;
    tr {
      th {
        padding-left: 0;
        .btn-link {
          font-weight: $font-weight-medium;
        }
        .btn {
          line-height: unset;
          .mdi {
            font-size: $table-header-icon-size;
            padding: 1px 0 0 1px;
            vertical-align: baseline;
            line-height: 0px;
          }
        }
        font-weight: $font-weight-medium;
      }
    }
    .btn-link {
      padding: 0;
      font-size: $caption-size;
      color: $gray;
      &:active,
      &:hover:active {
        color: $gray;
        background-color: transparent !important;
        text-decoration: none;
      }
      &:hover,
      &:focus {
        color: $gray;
        text-decoration: none;
      }
    }
  }
  tbody {
    @extend .caption3;
    tr {
      td {
        vertical-align: middle;
        border-top: 2px solid #ddd;
        border-bottom: 2px solid #ddd;
      }
    }
    .btn-link {
      padding: 0;
      text-transform: none;
      font-size: $caption-size;
    }
  }
}

// Forms ======================================================================

label {
  font-size: $placeholder-size;
  font-weight: $font-weight-medium;
  color: $gray;

  &[disabled] {
    color: $gray-light !important;
  }
}

textarea,
textarea.form-control,
input.form-control,
input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
[type='text'].form-control,
[type='password'].form-control,
[type='email'].form-control,
[type='tel'].form-control,
[contenteditable].form-control {
  padding: 5px 0 0 0;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  @include box-shadow(inset 0 -1px 0 $gray-lighter);
  font-size: $subheader-size;
  font-weight: $font-weight-light;
  color: $gray-light;

  &:focus {
    @include box-shadow(inset 0 -2px 0 $brand-info);
    color: $brand-info;
  }

  &[disabled],
  &[readonly] {
    @include box-shadow(none);
    border-bottom: 2px dotted $gray-lighter;
    color: $brand-disabled;
  }

  &.input {
    &-sm {
      font-size: $font-size-small;
    }

    &-lg {
      font-size: $font-size-large;
    }
  }
}

select,
select.form-control {
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 0;
  padding-right: 0\9; // remove padding for < ie9 since default arrow can't be removed
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAJ1BMVEVmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmaP/QSjAAAADHRSTlMAAgMJC0uWpKa6wMxMdjkoAAAANUlEQVR4AeXJyQEAERAAsNl7Hf3X6xt0QL6JpZWq30pdvdadme+0PMdzvHm8YThHcT1H7K0BtOMDniZhWOgAAAAASUVORK5CYII=);
  background-size: 13px;
  background-repeat: no-repeat;
  background-position: right center;
  @include box-shadow(inset 0 -1px 0 #ddd);
  font-size: 16px;
  line-height: 1.5;

  &::-ms-expand {
    display: none;
  }

  &.input {
    &-sm {
      font-size: $font-size-small;
    }

    &-lg {
      font-size: $font-size-large;
    }
  }

  &:focus {
    @include box-shadow(inset 0 -2px 0 $brand-primary);
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAJ1BMVEUhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISF8S9ewAAAADHRSTlMAAgMJC0uWpKa6wMxMdjkoAAAANUlEQVR4AeXJyQEAERAAsNl7Hf3X6xt0QL6JpZWq30pdvdadme+0PMdzvHm8YThHcT1H7K0BtOMDniZhWOgAAAAASUVORK5CYII=);
  }

  &[multiple] {
    background: none;
  }
}

.radio,
.radio-inline,
.checkbox,
.checkbox-inline {
  label {
    padding-left: 25px;
  }

  input[type='radio'],
  input[type='checkbox'] {
    margin-left: -25px;
  }
}

input[type='radio'],
.radio input[type='radio'],
.radio-inline input[type='radio'] {
  position: relative;
  margin-top: 6px;
  margin-right: 4px;
  vertical-align: top;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    @include transition(240ms);
  }

  &:before {
    position: absolute;
    left: 0;
    top: -3px;
    background-color: $brand-info;
    @include scale(0);
  }

  &:after {
    position: relative;
    top: -3px;
    border: 2px solid $gray;
  }

  &:checked:before {
    @include scale(0.5);
  }

  &:disabled:checked:before {
    background-color: $gray-light;
  }

  &:checked:after {
    border-color: $brand-info;
  }

  &:disabled:after,
  &:disabled:checked:after {
    border-color: $gray-light;
  }
}

input[type='checkbox'],
.checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'] {
  position: relative;
  border: none;
  margin-bottom: -4px;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:focus:after {
    border-color: $brand-info;
  }

  &:after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    margin-top: -2px;
    margin-right: 5px;
    border: 2px solid $gray;
    border-radius: 2px;
    @include transition(240ms);
  }

  &:checked:before {
    content: '';
    position: absolute;
    top: 0;
    left: 6px;
    display: table;
    width: 6px;
    height: 12px;
    border: 2px solid #fff;
    border-top-width: 0;
    border-left-width: 0;
    @include rotate(45deg);
  }

  &:checked:after {
    background-color: $brand-info;
    border-color: $brand-info;
  }

  &:disabled:after {
    border-color: $gray-light;
  }

  &:disabled:checked:after {
    background-color: $gray-light;
    border-color: transparent;
  }
}

.has-warning {
  input:not([type='checkbox']),
  .form-control,
  input.form-control[readonly],
  input[type='text'][readonly],
  [type='text'].form-control[readonly],
  input:not([type='checkbox']):focus,
  .form-control:focus {
    border-bottom: none;
    @include box-shadow(inset 0 -2px 0 $brand-warning);
  }
}

.has-error {
  input:not([type='checkbox']),
  .form-control,
  input.form-control[readonly],
  input[type='text'][readonly],
  [type='text'].form-control[readonly],
  input:not([type='checkbox']):focus,
  .form-control:focus {
    border-bottom: none;
    @include box-shadow(inset 0 -2px 0 $brand-danger);
    color: $brand-info;
  }
  .control-label {
    color: $brand-info;
  }
}

.has-success {
  input:not([type='checkbox']),
  .form-control,
  input.form-control[readonly],
  input[type='text'][readonly],
  [type='text'].form-control[readonly],
  input:not([type='checkbox']):focus,
  .form-control:focus {
    border-bottom: none;
    @include box-shadow(inset 0 -2px 0 $brand-success);
  }
}

// Remove the Bootstrap feedback styles for input addons
.input-group-addon {
  .has-warning &,
  .has-error &,
  .has-success & {
    color: $input-color;
    border-color: $input-group-addon-border-color;
    background-color: $input-group-addon-bg;
  }
}

.form-group-lg {
  select,
  select.form-control {
    line-height: 1.5;
  }
}

// Navs =======================================================================

.nav-tabs {
  > li > a,
  > li > a:focus {
    margin-right: 0;
    background-color: transparent;
    border: none;
    color: $navbar-default-link-color;
    @include box-shadow(inset 0 -1px 0 $gray);
    @include transition(all 0.2s);

    &:hover {
      background-color: transparent;
      @include box-shadow(inset 0 -2px 0 $brand-primary);
      color: $brand-primary;
    }
  }

  & > li.active > a,
  & > li.active > a:focus {
    border: none;
    @include box-shadow(inset 0 -2px 0 $brand-primary);
    color: $brand-primary;

    &:hover {
      border: none;
      color: $brand-primary;
    }
  }

  & > li.disabled > a {
    @include box-shadow(inset 0 -1px 0 #ddd);
  }

  &.nav-justified {
    & > li > a,
    & > li > a:hover,
    & > li > a:focus,
    & > .active > a,
    & > .active > a:hover,
    & > .active > a:focus {
      border: none;
    }
  }

  .dropdown-menu {
    margin-top: 0;
  }
}

.dropdown-menu {
  margin-top: 0;
  border: none;
  @include box-shadow(0 1px 4px rgba(0, 0, 0, 0.3));
}

// Indicators =================================================================

.alert {
  border: none;
  color: #fff;

  &-success {
    background-color: $brand-success;
  }

  &-info {
    background-color: $brand-info;
  }

  &-warning {
    background-color: $brand-warning;
  }

  &-danger {
    background-color: $brand-danger;
  }

  a:not(.close):not(.btn),
  .alert-link {
    color: #fff;
    font-weight: bold;
  }

  .close {
    color: #fff;
  }
}

.badge {
  padding: 4px 6px 4px;
}

.progress {
  position: relative;
  z-index: 1;
  height: 6px;
  border-radius: 0;

  @include box-shadow(none);

  &-bar {
    @include box-shadow(none);

    &:last-child {
      border-radius: 0 3px 3px 0;

      &:before {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        z-index: -1;
        background-color: lighten($progress-bar-bg, 35%);
      }
    }

    &-success:last-child.progress-bar:before {
      background-color: lighten($brand-success, 35%);
    }

    &-info:last-child.progress-bar:before {
      background-color: lighten($brand-info, 45%);
    }
    &-warning:last-child.progress-bar:before {
      background-color: lighten($brand-warning, 35%);
    }

    &-danger:last-child.progress-bar:before {
      background-color: lighten($brand-danger, 25%);
    }
  }
}

// Progress bars ==============================================================

// Containers =================================================================

.close {
  font-size: 34px;
  font-weight: $font-weight-light;
  line-height: 24px;
  opacity: 0.6;
  @include transition(all 0.2s);

  &:hover {
    opacity: 1;
  }
}

.list-group {
  &-item {
    padding: 15px;
  }

  &-item-text {
    color: $gray-light;
  }
}

.well {
  border-radius: 0;
  @include box-shadow(none);
}

.popover {
  border: none;
  @include box-shadow(0 1px 4px rgba(0, 0, 0, 0.3));
}

.carousel {
  &-caption {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: inherit;
    }
  }
}

.help-block {
  font-size: $helpblock-size;
}

// Headers =================================================================

h1,
.header1 {
  color: $brand-info;
  font-weight: $font-weight-light;
  background-color: transparent;
  &.small,
  small {
    font-size: $subheader-size;
  }
}

h2,
.header2 {
  color: $brand-info;
  font-weight: $font-weight-medium;
  background-color: transparent;
  &.small,
  small {
    color: $gray;
    font-weight: $font-weight-light;
    font-size: $subheader-size;
  }
}

h3,
.header3 {
  color: $brand-default;
  font-weight: $font-weight-light;
  background-color: $brand-info;
  &.small,
  small {
    color: $gray-light;
    font-weight: $font-weight-light;
    font-size: $subheader-size;
    background-color: transparent;
  }
}

h4,
.header4 {
  color: $brand-default;
  font-weight: $font-weight-medium;
  background-color: $brand-info;
}

.caption1 {
  font-size: $caption-size;
  font-weight: $font-weight-regular;
  color: $gray-base;
}

.caption2 {
  font-size: $caption-size;
  font-weight: $font-weight-regular;
  color: $brand-info;
}

.caption3 {
  font-size: $caption-size;
  font-weight: $font-weight-regular;
  color: $gray-light;
}

.caption4 {
  font-size: $caption-size;
  font-weight: $font-weight-medium;
  color: $gray;
}

// Modals =================================================================

.modal-header {
  h4 {
    @extend h1;
    margin: 0;
  }
}

// Pagination =================================================================
.pagination {
  li > a {
    width: 25px;
    height: 25px;
    padding: 2px 4px;
    text-align: center;
    border-radius: 3px;
    font-size: 14px;
    margin: 0 1px;
    color: $gray;
  }
  li:first-of-type > a,
  li:last-of-type > a {
    font-size: 18px;
    line-height: 16px;
  }
}

// Panel =================================================================
.panel {
  border: none;
  border-radius: 2px;
  @include box-shadow(0 1px 4px rgba(0, 0, 0, 0.3));

  h4 {
    @extend h1;
    margin: 0;
  }

  &-heading {
    border-bottom: none;
  }

  &-footer {
    border-top: none;
  }

  .panel-title {
    @extend h1;
    font-size: 20px;
    font-weight: 400;
  }
}
