@import '../../../styles/globals.scss';

.PaymentMethod {
  .external-payment {
    margin: 0px 0px 25px 25px;
    img.payment-type {
      margin: 0 8px;
    }
  }
  textarea {
    resize: vertical;
    min-height: 125px;
    background-color: white;
  }
  .panel {
    margin-left: 30px;
  }
  .horizontal-line {
    width: 100%;
    border-bottom: solid 1px silver;
  }
  .download-invoice,
  .forward-invoice {
    box-shadow: none;
    .panel-body {
      border-radius: 2px;
      background-color: $panel_background-color;
    }
  }
}

.credit_card {
  .external-payment {
    margin: 0;
  }
}
