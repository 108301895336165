.MuiRadio-root {
  input[type='radio'] {
    margin: 0 !important;
    position: absolute;
  }

  .MuiSvgIcon-root {
    font-size: 24px;
  }
}
