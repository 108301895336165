@import '../../../styles/globals.scss';
.Steps {
  $bubbleRadius: 35px;
  $bubbleRadiusMobile: 20px;
  $border-color: $brand-info;
  $pageNameColor: $brand-info;
  $defaultBackgroundColor: $brand-default;
  $activeBackgroundColor: $brand-info;
  $activePageNameColor: $brand-info;
  $backgroundColorHover: lighten($brand-info, 50%);
  $activeTextColor: $brand-default;
  $textColor: $brand-info;

  padding: 20px 0;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;

  .Content {
    flex: 1;
    display: flex;
    flex-flow: row nowrap;

    .step-button-container {
      flex: 1;
      position: relative;
    }
  }

  .mobile {
    display: none;
  }
  @media screen and (max-width: $mobileModeWidth) {
    .mobile {
      display: inherit;
    }
    .desktop {
      display: none !important;
    }
  }
  .row {
    [class*='col-'] {
      color: $pageNameColor;

      span {
        display: block;
        font-size: 12px;
        font-weight: 500;
      }

      &.active {
        color: $activePageNameColor;
        transition: color 250ms linear;
        .step-button {
          button {
            background-color: $activeBackgroundColor;
            color: $activeTextColor;
          }
        }
      }

      .step-button {
        list-style-type: none;
        padding: 1rem;
        button {
          box-shadow: none;
          display: inline-block;
          border: 2px solid;
          border-radius: 50%;
          font-size: 12px;
          padding: 0;
          border-color: $border-color;
          color: $brand-info;
          background-color: $defaultBackgroundColor;
          height: $bubbleRadius;
          width: $bubbleRadius;
          transition: all 250ms linear;
          transition-property: background-color, color;
          @media screen and (max-width: $mobileModeWidth) {
            height: $bubbleRadiusMobile;
            width: $bubbleRadiusMobile;
            margin-top: -$bubbleRadiusMobile/3;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            font-size: 0;
            color: transparent;
          }
          &:not(.not-clickable):hover {
            background-color: $backgroundColorHover;
            color: $activeTextColor;
            cursor: pointer;
          }
          &:hover {
            cursor: not-allowed;
            text-decoration: none;
          }
          &:active {
            @include remove-grey-click-effect();
          }
        }
      }
    }

    [class*='col-']:not(:last-child) {
      .step-button {
        button:after {
          content: '';
          background-color: $border-color;
          position: absolute;
          width: 100%;
          margin-left: $bubbleRadius / 2;
          height: 2px;
          top: $bubbleRadius / 1.2;
          left: 50%;
          display: block;
          @media screen and (max-width: $mobileModeWidth) {
            margin-left: $bubbleRadiusMobile;
            top: $bubbleRadiusMobile / 1.2;
          }
        }
      }
    }
  }
}
