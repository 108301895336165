@import '../../../styles/globals.scss';

.BenefitSummary {
  .total {
    color: $gray;
  }
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 0;
    padding: 0;
    color: $hr-color;
  }
}
