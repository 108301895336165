@import '../../../styles/globals.scss';
.DeliveryInfo {
  .subpanel {
    margin: 15px 35px;
  }
  .pickup-details-row {
    height: 125px;
  }
  .explanatory-text,
  .street_sublabel {
    margin-left: 33px;
    margin-bottom: 5px;
  }
}
