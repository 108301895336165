@import '../../../styles/globals.scss';
.Profile {
  margin: 30px;

  ul {
    padding: 0;
  }

  .username-row {
    .spinner-container {
      margin-left: -50px;
    }
  }

  @include mobile-tabs();
  @include mobile-tabs-smaller-font(430px, 9px);
}
