.MuiInput-root {
  input[type='text']:focus {
    box-shadow: none;
  }

  .Mui-disabled.MuiInputBase-input,
  &.Mui-disabled {
    padding-left: 0 !important;
    background-color: transparent !important;
  }
}
