@import '../../../styles/globals.scss';
.BenefitCalculator {
  margin: 0;
  padding: 0;
  width: 100%;

  button.btn-calculate {
    border-radius: 100px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .left-margin {
    margin-left: -30px;
  }
  .date-range .form-group {
    width: calc(50% - 5px);
    min-width: 135px;
    display: inline-block;
    margin-right: 5px;
  }
  @media screen and (max-width: 360px) {
    button.btn-calculate {
      min-width: 94px;
    }
  }
  .calculator-button,
  .calculator-button:focus {
    margin-left: 20px;
    line-height: 34px;
    box-shadow: none;
    white-space: normal;
    .text {
      display: inline-block;
    }
    .mdi-calculator {
      height: 100%;
      line-height: 34px;
    }
    @media screen and (max-width: 450px) {
      position: relative;
      min-height: 1px;
      width: 91.66667%;
      .mdi-calculator {
        display: none;
      }
    }
  }
  .employees-number-field {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-bottom: -12px;
  }
  .calculator-panel {
    margin-bottom: 5px;
    box-shadow: none;
    .panel-body {
      padding: 15px 0;
    }
  }
  .tooltip-info {
    float: right;
  }
}
