@import '../../../styles/globals.scss';

.ResetPassword {
  padding: 20px 20px 30px 20px;

  h1 {
    text-align: center;
  }

  p {
    margin: 5px 0 10px 0;
    line-height: 1.6;
  }

  .button-margin {
    margin: 0;
    [class*='col-'] {
      padding: 0;
    }

    button {
      margin-top: 15px;
    }
  }

  @media screen and (max-width: $mobileModeWidth) {
    p {
      margin: 0 0 10px 0;
    }
  }
}
