@import '../../../styles/globals.scss';

.LoadingContainer {
  & > .spinner-container {
    width: 100%;
  }
  .content {
    &.loading {
      display: none;
    }
  }
}
