@import '../../../styles/globals.scss';
.DeliveryAddressPicker {
  .panel-heading {
    h1 {
      margin-top: 5px;
      margin-bottom: 0;
      line-height: 1.5;
    }
  }

  .voucher-value-field {
    padding-left: 0;
  }

  .btn-edit {
    margin-top: 20px;
  }
}
