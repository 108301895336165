@import '../../../../styles/globals.scss';

.ExternalPayment {
  .payments-methods-not-loaded {
    margin-bottom: 10px;
    margin-top: -5px;
    padding-left: 8px;
  }

  &.external-payment {
    display: flex;
    flex-wrap: wrap;

    button {
      border: none;
      background: none;
      outline: none;
      padding: 2px 16px;

      &:hover {
        box-shadow: none;
      }

      &:disabled {
        img {
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
          opacity: 0.8;
        }
      }
    }
  }
}
